@use '@/variables' as v;

svg {
    text-rendering: optimizeSpeed;
}

svg.roadmap-cell {
    width: 100%;
    height: 100%;
}

.lite-ui svg {
    shape-rendering: optimizeSpeed;
}

.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}
.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.p-0 {
    padding: 0;
}

.m-0 {
    margin: 0;
}

.self-align-center {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}
// /* width */
// ::-webkit-scrollbar {
//     width: 5px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 5px grey;
//     border-radius: 10px;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//     background: #bd9f71b3;
//     border-radius: 5px;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//     background: #555;
// }

svg .ico_pri {
    fill: #979797;
    fill-rule: evenodd;
    clip-rule: evenodd;
}

.my-font {
    &-8 {
        @media (min-width: v.$breakpoint-xs) {
            font-size: v.$font-8 !important;
        }

        @media (min-aspect-ratio: 4/3) {
            font-size: max(
                calc(var(--vh) * 8 * v.$px-to-vh),
                v.$font-8
            ) !important;
        }
    }

    &-10 {
        @media (min-width: v.$breakpoint-xs) {
            font-size: v.$font-10 !important;
        }

        @media (min-aspect-ratio: 4/3) {
            font-size: max(
                calc(var(--vh) * 10 * v.$px-to-vh),
                v.$font-10
            ) !important;
        }
    }

    &-12 {
        @media (min-width: v.$breakpoint-xs) {
            font-size: v.$font-12 !important;
        }

        @media (min-aspect-ratio: 4/3) {
            font-size: max(
                calc(var(--vh) * 12 * v.$px-to-vh),
                v.$font-12
            ) !important;
        }
    }

    &-13 {
        @media (min-width: v.$breakpoint-xs) {
            font-size: v.$font-13 !important;
        }

        @media (min-width: v.$breakpoint-lg) {
            font-size: v.$font-13-lg !important;
        }

        @media (min-aspect-ratio: 4/3) and (min-width: v.$breakpoint-xs) {
            font-size: max(
                calc(var(--vh) * 13 * v.$px-to-vh),
                v.$font-13
            ) !important;
        }

        @media (min-aspect-ratio: 4/3) and (min-width: v.$breakpoint-lg) {
            font-size: max(
                calc(var(--vh) * 13 * v.$px-to-vh),
                v.$font-13-lg
            ) !important;
        }
    }

    &-14 {
        @media (min-width: v.$breakpoint-xs) {
            font-size: v.$font-14 !important;
        }

        @media (min-width: v.$breakpoint-lg) {
            font-size: v.$font-14-lg !important;
        }

        @media (min-aspect-ratio: 4/3) and (min-width: v.$breakpoint-xs) {
            font-size: max(
                calc(var(--vh) * 14 * v.$px-to-vh),
                v.$font-14
            ) !important;
        }

        @media (min-aspect-ratio: 4/3) and (min-width: v.$breakpoint-lg) {
            font-size: max(
                calc(var(--vh) * 14 * v.$px-to-vh),
                v.$font-14-lg
            ) !important;
        }
    }

    &-16 {
        @media (min-width: v.$breakpoint-xs) {
            font-size: v.$font-16 !important;
        }

        @media (min-aspect-ratio: 4/3) {
            font-size: max(
                calc(var(--vh) * 16 * v.$px-to-vh),
                v.$font-16
            ) !important;
        }
    }

    &-18 {
        @media (min-width: v.$breakpoint-xs) {
            font-size: v.$font-18 !important;
        }

        @media (min-aspect-ratio: 4/3) {
            font-size: max(
                calc(var(--vh) * 18 * v.$px-to-vh),
                v.$font-18
            ) !important;
        }
    }

    &-20 {
        @media (min-width: v.$breakpoint-xs) {
            font-size: v.$font-20 !important;
        }

        @media (min-aspect-ratio: 4/3) {
            font-size: max(
                calc(var(--vh) * 20 * v.$px-to-vh),
                v.$font-20
            ) !important;
        }
    }
}

// disable mui button hover background
.MuiTouchRipple-root {
    display: none;
}

.hide-scroll,
.hide-scroll .MuiDialog-paperScrollPaper {
    overflow: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
    &-ms-overflow-style {
        display: none;
    }
}

.lobby-footer,
.game-footer {
    color: rgba($color: #ffffff, $alpha: 0.8);
}
// https://stackoverflow.com/questions/44543157/how-to-hide-the-google-invisible-recaptcha-badge/53986985#53986985
.grecaptcha-badge {
    visibility: hidden !important;
}

.lobby-header,
.game-header {
    svg.menu-icon {
        width: 100%;
        height: 100%;
    }
}

.fake-linear-progress {
    background-color: #6c6c6c;
    height: 4px;
}

.non-overflow-hidden {
    overflow: visible !important;
}

.round-top-left {
    border-top-left-radius: var(--vh);
}
.round-top-right {
    border-top-right-radius: var(--vh);
}
.round-bottom-left {
    border-bottom-left-radius: var(--vh);
}
.round-bottom-right {
    border-bottom-right-radius: var(--vh);
}
.betArea-container.blue-style {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid #ffffff33;
    background: linear-gradient(
        180deg,
        #34538e 0%,
        rgba(52, 83, 142, 0.6) 100%
    );
    color: #b8d0ff;
}
.betArea-container.green-style {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid #ffffff33;
    background: linear-gradient(
        180deg,
        #519c6a 0%,
        rgba(81, 156, 106, 0.6) 100%
    );
    color: #a7efbf;
}
.betArea-container.red-style {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid #ffffff33;
    background: linear-gradient(
        180deg,
        #ac2424 0%,
        rgba(172, 36, 36, 0.6) 100%
    );
    color: #ffbdbd;
}

.betArea-container.brown-style {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid #ffffff33;
    background: linear-gradient(
        180deg,
        rgba(89, 81, 68, 0.75) 15%,
        rgba(70, 61, 46, 0.6) 100%
    );
    color: #fffbf5;
}
.betArea-container.light-gold-style {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(128, 128, 128, 0.3);
    background: linear-gradient(
        180deg,
        #c3a475 0%,
        rgba(195, 164, 117, 0.6) 100%
    );
    color: #fffbf5;
}
.betArea-container.dark-green-style {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid rgba(128, 128, 128, 0.4);
    background: linear-gradient(180deg, #2a4737 0%, #1e3d2c 100%);
    color: #fffbf5;
}
