.progress.loading {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: 200px;

    position: relative;
    width: 100%;
    height: calc(100% - 8rem);
    min-height: 180px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
        position: relative;
        top: 0;
        width: 42%;
        max-width: 200px;
        min-width: 80px;
        height: auto;
        max-height: 42vh;
        object-fit: contain;

        @media screen and (min-aspect-ratio: 4/3) {
            top: -8%;
        }
    }

    .loading-icon {
        position: absolute;
        width: 68%;
        max-width: 360px;
        min-width: 170px;
        height: auto;
        -webkit-animation: spin 6s linear infinite;
        -moz-animation: spin 6s linear infinite;
        animation: spin 6s linear infinite;
    }
}
.progress.loading.overlay {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cert-logo {
    .gaming-logo {
        width: 140px;
        max-width: 48vw;
        max-height: 15vh;
        height: auto;
        object-fit: contain;
    }
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
