.event-container {
    width: 540px;
    height: 960px;

    @media (min-aspect-ratio: 4/3) {
        width: 1920px;
        height: 1080px;
    }

    * {
        text-transform: none !important;
    }

    .btn-close {
        color: white;
        position: absolute;
        min-width: 30px;
        min-height: 30px;
        top: 66.5px;
        right: 30px;
        padding: 0;
        margin: 0;

        @media (min-aspect-ratio: 4/3) {
            top: 70px;
            right: 330px;
            min-width: 40px;
            min-height: 40px;
        }
    }

    .panel {
        position: absolute;
        width: 500px;
        height: 825px;
        // top: 100px;
        top: 56.5px;
        left: 50%;
        transform: translateX(-50%);

        @media (min-aspect-ratio: 4/3) {
            top: 50px;
            width: 1300px;
            height: 936px;
        }

        .total-prize-container {
            position: absolute;
            // top: 470px;
            top: 440px;
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 8px;

            @media (min-aspect-ratio: 4/3) {
                // top: 438px;
                top: calc(488px * 0.8);
                zoom: 1.2;
            }

            .event-total-prize-bg {
                left: 50%;
                transform: translateX(-50%);
                position: absolute;
                top: 12px;
                margin: 0;
            }

            .total-prize-title-container {
                left: 50%;
                // transform: translate(-50%, -15%);
                transform: translate(-50%, 12%);
                position: relative;
                display: flex;
                justify-content: center;
                margin: 0;
                text-align: center;
                border-radius: 100px;
                border: 2px solid #d2b463;
                background: #110b04;
                position: relative;
                padding: 4px 14px;
                width: fit-content;

                .total-prize-title-text {
                    white-space: nowrap;
                    color: #ffe09d;
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }

        .total-prize {
            position: relative;
            top: 2px;
            height: 62px;
        }

        .event-date {
            position: relative;
            top: 25px;

            > * {
                font-size: 16px;
                color: #bf9a5d;
            }
        }

        .event-period {
            position: relative;
            display: flex;
            justify-content: center;
            top: 25px;
            color: #c6c6c6;
            height: 24px;
        }

        .event-remain-time {
            position: relative;
            top: 35px;
            height: 52px;
        }

        .event-title {
            position: relative;
            top: 10px;
            display: flex;
            justify-content: center;
            zoom: 0.8;

            @media (min-aspect-ratio: 4/3) {
                zoom: 1;
            }
        }

        .gacha-content {
            //  sx={{ position: 'relative', top: '8.5px' }}
            position: relative;
            top: 8.5px;

            @media (min-aspect-ratio: 4/3) {
                zoom: 1.2;
            }
        }

        .gacha-details {
            position: relative;
            top: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
            pointer-events: none;
            height: 90px;

            @media (min-aspect-ratio: 4/3) {
                position: absolute;
                width: 100%;
                height: 100%;
            }
        }

        .gacha-total-point {
            position: relative;
            display: flex;
            top: 12px;
            height: 32px;
            align-items: center;
            justify-content: center;

            @media (min-aspect-ratio: 4/3) {
                position: absolute;
                left: 36px;
                top: -40px;
            }
        }

        .gacha-total-balance {
            position: relative;
            align-items: center;
            border-radius: 100px;
            border: 1px solid #bf9a5d;
            background: rgba(0, 0, 0, 0.4);
            height: 36px;
            padding-left: 12px;
            padding-right: 12px;
            margin-top: 5px;
            zoom: 1;

            @media (min-aspect-ratio: 4/3) {
                position: absolute;
                right: 36px;
                top: -67px;
                margin-top: 0;
                max-height: 32px;
            }
        }

        .gacha-redeemed-times {
            justify-content: center;
            @media (min-aspect-ratio: 4/3) {
                position: absolute;
                left: 36px;
                bottom: 0;
            }
        }

        .gacha-remain-times {
            justify-content: center;
            align-items: center;
            font-size: 14px;
            @media (min-aspect-ratio: 4/3) {
                position: absolute;
                right: 36px;
                bottom: 0;
                font-size: 18px;
            }
        }

        .event-detail-list-content {
            position: relative;
            top: 17px;

            @media (min-aspect-ratio: 4/3) {
                zoom: 1.2;
            }
        }

        .record-title-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            color: #979797;
            padding: 5px 0;
        }

        .record-list {
            width: 100%;
            min-height: 540px;
            max-height: 540px;
            // overflow-y: auto;

            @media (min-aspect-ratio: 4/3) {
                min-height: 494px;
                max-height: 494px;
            }

            .record-list-item {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                width: 100%;
                height: 60px;
                overflow: hidden;
                text-wrap: nowrap;
            }
        }

        .record-item-date {
            width: 40%;
            font-size: 18px;
            padding-left: 20px;

            @media (min-aspect-ratio: 4/3) {
                padding-left: calc(20px + 180px);
            }
        }
        .record-item-prize-level {
            width: 20%;
            font-size: 18px;
            max-height: 27px;
            text-wrap: wrap;
            text-align: center;
        }
        .record-item-win-amount {
            width: 40%;
            font-size: 18px;
            text-align: right;
            padding-right: 20px;

            @media (min-aspect-ratio: 4/3) {
                padding-right: calc(20px + 180px);
            }
        }
        .record-item-redemption-id {
            color: #979797;
            font-size: 14px;
            padding-left: 20px;

            @media (min-aspect-ratio: 4/3) {
                padding-left: calc(20px + 180px);
            }
        }

        .ranking-title-container {
            width: 100%;
            display: flex;
            color: #979797;
            text-align: center;
            padding: 5px 18px;
            justify-content: center;

            @media (min-aspect-ratio: 4/3) {
                padding: 1px 18px;
                gap: 6%;
            }

            .ranking-title-no {
                min-width: 48px;
                font-size: 18px;
            }
            .ranking-title-player {
                width: 204px;
                margin: 0 20px;
                font-size: 18px;
            }
            .ranking-title-win-amount {
                min-width: 172px;
                font-size: 18px;
                text-align: right;
            }
        }

        .ranking-list {
            width: 100%;
            min-height: 470px;
            max-height: 470px;
            // overflow-y: auto;

            @media (min-aspect-ratio: 4/3) {
                min-height: 450px;
                max-height: 450px;
            }

            .ranking-list-item {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                width: 100%;
                height: 52px;
                overflow: hidden;
                justify-content: center;

                @media (min-aspect-ratio: 4/3) {
                    gap: 6%;
                }
            }
        }

        .ranking-current-player {
            width: 100%;
            height: 60px;
            background: rgba(35, 31, 32, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;

            @media (min-aspect-ratio: 4/3) {
                gap: 6%;
            }
        }

        .ranking-item-rank {
            max-width: 48px;
            max-height: 48px;
            display: flex;
            justify-content: center;
            margin-left: 20px;
        }

        .ranking-item-player {
            margin-left: 20px;
            margin-right: 20px;
            width: 204px;
            text-align: center;
            font-size: 20px;
        }

        .ranking-item-amount {
            margin-right: 20px;
            text-align: right;
            width: 168px;
            font-size: 20px;
        }

        .ranking-last-update {
            margin: 12px 20px;
            display: flex;
            justify-content: flex-end;

            @media (min-aspect-ratio: 4/3) {
                margin: 5px 20px;
            }
        }

        .page-buttons {
            position: absolute;
            width: 50%;
            bottom: 2%;
            width: 100%;
            height: 48px;
            justify-content: center;

            @media (min-aspect-ratio: 4/3) {
                zoom: 1.2;
            }
        }

        .snooze-button {
            position: absolute;
            width: 50%;
            bottom: -30px;
            justify-content: start;
            padding: 0;

            @media (min-aspect-ratio: 4/3) {
                bottom: -25px;
                zoom: 1.2;
            }
        }

        .more-details {
            position: absolute;
            left: 50%;
            width: 50%;
            bottom: -30px;
            justify-content: end;
            padding: 0;

            @media (min-aspect-ratio: 4/3) {
                bottom: -25px;
                zoom: 1.2;
            }
        }

        .no-promotion-records {
            position: absolute;
            width: 100%;
            top: 200px;
            text-align: center;
            font-size: 24px;

            @media (min-aspect-ratio: 4/3) {
                font-size: 28px;
                top: 240px;
            }
        }
    }

    .rotate180 {
        transform: rotateY(180deg);
    }

    .time-text {
        font-size: 12px;
        text-align: center;
        width: 80px;
        white-space: nowrap;
    }

    .button_gold,
    .button_black {
        width: 220px;
        height: 48px;
        line-height: 48px;
        border-radius: 4px;
        text-align: center;
        white-space: nowrap;
        font-size: 20px;
    }

    .button_gold {
        background-color: #bf9a5d !important;
        color: #ffffff;
    }

    .button_black {
        background-color: #171717 !important;
        border: 1px solid #bf9a5d;
        color: #bf9a5d;
    }

    .gacha_prize_text {
        position: relative;
        top: 15px;
    }

    .prize_selected_text,
    .gold_unselected_text,
    .silver_unselected_text,
    .bronze_unselected_text {
        top: -100px;
        font-size: 20px;
        font-weight: 700;
    }

    .prize_selected_text {
        color: #231f20;
    }

    .gold_unselected_text {
        color: #e4ce92;
    }

    .silver_unselected_text {
        color: #9fa1a0;
    }

    .bronze_unselected_text {
        color: #b08773;
    }

    .selected_gem_point,
    .unselected_gem_point {
        top: -100px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        min-height: 24px;
    }

    .selected_gem_point {
        color: #392b0b;
    }

    .unselected_gem_point {
        color: rgba(#b8b8b8, 0.8);
    }

    .btn_scratch,
    .btn_disabled,
    .btn_scratched {
        font-size: 24px;
        font-weight: 500;
    }

    .btn_scratch {
        color: #f6f6f6;
    }

    .btn_disabled {
        color: #f6f6f6;
    }

    .btn_scratched {
        color: #bf9a5d;
    }

    @keyframes balanceEffect {
        0% {
            opacity: 0;
        }

        20% {
            opacity: 0.5;
        }

        40% {
            opacity: 0;
        }

        60% {
            opacity: 0.5;
        }

        80% {
            opacity: 0;
        }

        100% {
            opacity: 0.5;
        }
    }

    .balance_effect {
        position: absolute;
        left: 0;
        width: 100%;
        height: 36px;
        background: #ffffff;
        opacity: 0.5;
        animation: balanceEffect 3s infinite;
        border-radius: 100px;
    }

    .btn_black,
    .btn_gold {
        position: relative;
        width: auto;
        // min-width: 140px;
        min-width: 220px;
        height: 48px;
        font-size: 20px;
        border: #bf9a5d solid 2px;
        border-radius: 8px;
        padding: 0 16px;
    }

    .btn_black {
        background: #171717;
        color: #bf9a5d;
        // border-top: #bf9a5d solid 2px;
        // border-bottom: #bf9a5d solid 2px;
        // background-repeat: repeat-x;
        // background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 48'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %23171717; %7D .cls-1, .cls-2 %7B stroke-width: 0px; %7D .cls-2 %7B fill: %23bf9a5d; %7D %3C/style%3E%3C/defs%3E%3Crect class='cls-2' y='46' width='10' height='2'/%3E%3Crect class='cls-2' width='10' height='2'/%3E%3Crect class='cls-1' y='2' width='10' height='44'/%3E%3C/svg%3E");
    }

    .btn_gold {
        background: linear-gradient(#d4af6f, #7e6336);
        color: #ffffff;
        // border-top: #bf9a5d solid 2px;
        // border-bottom: #bf9a5d solid 2px;
        // background-repeat: repeat-x;
        // background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 10 48'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: url(%23linear-gradient); %7D .cls-1, .cls-2 %7B stroke-width: 0px; %7D .cls-2 %7B fill: %23bf9a5d; %7D %3C/style%3E%3ClinearGradient id='linear-gradient' x1='5' y1='1447.4' x2='5' y2='1392.29' gradientTransform='translate(0 1446) scale(1 -1)' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23deb776'/%3E%3Cstop offset='.83' stop-color='%23806538'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect class='cls-2' y='46' width='10' height='2'/%3E%3Crect class='cls-2' width='120' height='2'/%3E%3Crect class='cls-1' y='2' width='120' height='44'/%3E%3C/svg%3E");
    }

    // .btn_black::before,
    // .btn_gold::before,
    // .btn_black::after,
    // .btn_gold::after {
    //     position: absolute;
    //     content: '';
    //     width: 18px;
    //     height: 48px;
    //     top: -2px;
    // }

    // .btn_black::before,
    // .btn_gold::before {
    //     left: -17px;
    // }

    // .btn_black::after,
    // .btn_gold::after {
    //     right: -17px;
    // }

    // .btn_black::before {
    //     background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 48'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %23171717; %7D .cls-1, .cls-2 %7B stroke-width: 0px; %7D .cls-2 %7B fill: %23bf9a5d; %7D %3C/style%3E%3C/defs%3E%3Cpolygon class='cls-2' points='15.15 46 2.32 24 15.15 2 18 2 18 0 14 0 0 24 14 48 18 48 18 46 15.15 46'/%3E%3Cpolygon class='cls-1' points='15.15 2 2.32 24 15.15 46 18 46 18 2 15.15 2'/%3E%3C/svg%3E");
    //     background-repeat: no-repeat;
    //     background-size: cover;
    // }

    // .btn_gold::before {
    //     background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 18 48'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: url(%23linear-gradient); %7D .cls-1, .cls-2 %7B stroke-width: 0px; %7D .cls-2 %7B fill: %23bf9a5d; %7D %3C/style%3E%3ClinearGradient id='linear-gradient' x1='10.16' y1='1447.4' x2='10.16' y2='1392.29' gradientTransform='translate(0 1446) scale(1 -1)' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23deb776'/%3E%3Cstop offset='.83' stop-color='%23806538'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpolygon class='cls-2' points='15.15 46 2.32 24 15.15 2 18 2 18 0 14 0 0 24 14 48 18 48 18 46 15.15 46'/%3E%3Cpolygon class='cls-1' points='15.15 2 2.32 24 15.15 46 18 46 18 2 15.15 2'/%3E%3C/svg%3E");
    //     background-repeat: no-repeat;
    //     background-size: cover;
    // }

    // .btn_black::after {
    //     background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 48'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: %23171717; %7D .cls-1, .cls-2 %7B stroke-width: 0px; %7D .cls-2 %7B fill: %23bf9a5d; %7D %3C/style%3E%3C/defs%3E%3Cpolygon class='cls-2' points='2.85 2 15.68 24 2.85 46 0 46 0 48 4 48 18 24 4 0 0 0 0 2 2.85 2'/%3E%3Cpolygon class='cls-1' points='2.85 46 15.68 24 2.85 2 0 2 0 46 2.85 46'/%3E%3C/svg%3E");
    //     background-repeat: no-repeat;
    //     background-size: cover;
    // }

    // .btn_gold::after {
    //     background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 18 48'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: url(%23linear-gradient); %7D .cls-1, .cls-2 %7B stroke-width: 0px; %7D .cls-2 %7B fill: %23bf9a5d; %7D %3C/style%3E%3ClinearGradient id='linear-gradient' x1='7.84' y1='1447.4' x2='7.84' y2='1392.29' gradientTransform='translate(0 1446) scale(1 -1)' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23deb776'/%3E%3Cstop offset='.83' stop-color='%23806538'/%3E%3C/linearGradient%3E%3C/defs%3E%3Cpolygon class='cls-2' points='0 0 0 2 2.85 2 15.68 24 2.85 46 0 46 0 48 4 48 18 24 4 0 0 0'/%3E%3Cpolygon class='cls-1' points='2.85 46 15.68 24 2.85 2 0 2 0 46 2.85 46'/%3E%3C/svg%3E");
    //     background-repeat: no-repeat;
    //     background-size: cover;
    // }

    .btn_invalid {
        filter: brightness(60%);
    }
}
