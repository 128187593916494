@use '@/variables' as v;
.PopupDialog {
    background: '#FFFFFF';
    .box {
        border-radius: 0.16667rem;
        background: linear-gradient(
            94.87deg,
            rgba(33, 28, 28, 0.8) 0%,
            rgba(77, 71, 64, 0.64) 100%
        );
        box-shadow: 0px 0px 30px 0px #00000059;
        backdrop-filter: blur(40px);
        padding: 1.08333rem 1.54167rem 0.83333rem 1.54167rem;
        min-width: 17.9rem;
    }
}
.PopupDialog svg {
    height: 2.1rem;
    width: 2.1rem;
    @media (min-aspect-ratio: 4/3) {
        height: max(calc(var(--vh) * 2.1 * v.$rem-to-vh), 2.1rem);
        width: max(calc(var(--vh) * 2.1 * v.$rem-to-vh), 2.1rem);
    }
}
