html,
body {
    touch-action: pan-x pan-y; //disable double click -> zoom in
    -ms-touch-action: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    margin: 0;
    padding: 0;
    border: 0;
    -webkit-tap-highlight-color: transparent;
    // font-family: -apple-system, BlinkMacSystemFont, 'Google Sans', 'Segoe UI',
    //     'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    //     'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    height: 100%;
    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
            monospace;
    }
    overflow: hidden;
    pre {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
            'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
            'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    background: linear-gradient(
        180deg,
        #221e1a 0%,
        #332e28 26%,
        #2c2824 67.42%,
        #332e2b 99.83%
    );
}
